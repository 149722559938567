import { css, Global } from '@emotion/react'
import { Color } from 'src/models/color'

/**
 * # GlobalStyles
 * @description
 * 전역적으로 사용되는 css를 추가하는 컴포넌트입니다.
 *
 * 전역적으로 적용되어야 하는 css는 꼭! 이 컴포넌트에 추가해야합니다.
 *
 * @example```tsx
 * <GlobalStyles />
 * ```
 */
export function GlobalStyles() {
  return (
    <Global
      styles={css`
        @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');

        :root {
          --font-family:
            'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
            'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          --code-font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
        }

        html {
          /** ios safari fixed bottom 대응 */
          height: 100%;
          overflow-x: hidden;
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
          background-color: ${Color.primary1};
        }

        html,
        body {
          font-family: var(--font-family) !important;
          color: ${Color.primary2};
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;

          font-size: 16px;
          @media screen and (max-width: 1024px) {
            font-size: 14px;
          }
        }

        /* http://meyerweb.com/eric/tools/css/reset/ 
          v2.0 | 20110126
          License: none (public domain)
        */
        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video,
        button,
        input,
        hr {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
          outline: none;
          appearance: none;
        }
        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        menu,
        nav,
        section {
          display: block;
        }
        body {
          line-height: 1;
        }
        ol,
        ul {
          list-style: none;
        }
        blockquote,
        q {
          quotes: none;
        }
        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
        * {
          box-sizing: border-box;
        }
      `}
    />
  )
}

import React, { useEffect } from 'react'
import { useTerminalStore } from './store'
import { useTerminalCommands } from './TerminalCommands'
import { navigate } from 'gatsby'
import { Post } from './postData'

// 명령어 인터페이스
interface Command {
  execute: (args: string[]) => string
}

interface Props {
  command: string
}

// 명령어 프로세서 컴포넌트
export function CommandProcessor({ command }: Props) {
  const { addToOutput } = useTerminalStore()
  const { posts, isUsingRealData } = useTerminalCommands()

  useEffect(() => {
    const executeCommand = async () => {
      const parts = command.trim().split(/\s+/)
      const commandName = parts[0]
      const args = parts.slice(1)

      const result = commandFactory(commandName, posts, isUsingRealData).execute(args)
      addToOutput(result)
    }

    executeCommand()
  }, [command, addToOutput, posts, isUsingRealData])

  return null // 이 컴포넌트는 UI를 렌더링하지 않음
}

// 도움말 명령어
class HelpCommand implements Command {
  constructor(private isUsingRealData: boolean) {}

  execute(): string {
    return `사용 가능한 명령어:
  help              - 명령어 목록 표시
  ls                - 블로그 글 목록 표시
  ls -t [태그명]      - 특정 태그의 블로그 글 목록 표시
  mv [식별자]        - 특정 블로그 글로 이동

[식별자]는 다음 중 하나를 사용할 수 있습니다:
  - 숫자 ID (post-1, post-2, ...)
  - 글 슬러그 (slug)
  - 글 제목 일부분
`
  }
}

// 목록 보기 명령어
class ListCommand implements Command {
  constructor(private posts: Post[]) {}

  execute(args: string[]): string {
    // 태그 필터링 옵션이 있는 경우
    if (args.length >= 2 && args[0] === '-t') {
      const tag = args[1]
      const filteredPosts = this.posts.filter((post) => {
        // 태그가 배열인 경우
        if (Array.isArray(post.tags)) {
          return post.tags.includes(tag)
        }
        // 태그가 문자열인 경우 (쉼표로 구분된 태그 문자열로 가정)
        else if (typeof post.tags === 'string') {
          const tagStr = post.tags as unknown as string
          return tagStr
            .split(',')
            .map((t: string) => t.trim())
            .includes(tag)
        }
        // 태그가 undefined인 경우
        return false
      })

      if (filteredPosts.length === 0) {
        return `태그 '${tag}'를 가진 글이 없습니다.`
      }

      return `태그 '${tag}'를 가진 글 목록:\n${filteredPosts.map((post) => `  ${post.shortId} - ${post.title}`).join('\n')}`
    }

    // 일반 목록에 포스트 개수 표시
    return `블로그 글 목록 (총 ${this.posts.length + 1}개):\n${'  main - 블로그 홈\n' + this.posts.map((post) => `  ${post.shortId} - ${post.title}\n    태그: ${post.tags.join(', ')}`).join('\n')}`
  }
}

// 이동 명령어
class MoveCommand implements Command {
  constructor(private posts: Post[]) {}

  execute(args: string[]): string {
    if (args.length === 0) {
      return '이동할 글의 식별자를 입력해주세요. (숫자 ID, 슬러그, 또는 제목 일부)'
    }

    const identifier = args.join(' ').toLowerCase()

    if (identifier === 'main') {
      navigate('/')
      return '메인 페이지로 이동합니다...'
    }

    // 1. 정확한 shortId 매칭
    let post = this.posts.find((p) => p.shortId.toLowerCase() === identifier)

    // 2. 슬러그 매칭
    if (!post) {
      post = this.posts.find((p) => p.slug.toLowerCase() === identifier || p.slug.toLowerCase().includes(identifier))
    }

    // 3. 제목 부분 매칭
    if (!post) {
      post = this.posts.find((p) => p.title.toLowerCase().includes(identifier))
    }

    // 4. 정확한 id 매칭 (최후의 수단)
    if (!post) {
      post = this.posts.find((p) => p.id === identifier)
    }

    if (!post) {
      return `'${identifier}'에 해당하는 글을 찾을 수 없습니다.`
    }

    // 해당 포스트 페이지로 이동
    navigate(post.slug.startsWith('/') ? post.slug : `/${post.slug}`)
    return `'${post.title}' 글로 이동합니다...`
  }
}

// 알 수 없는 명령어
class UnknownCommand implements Command {
  constructor(private commandName: string) {}

  execute(): string {
    return `알 수 없는 명령어입니다: ${this.commandName}. 'help'를 입력하여 사용 가능한 명령어를 확인하세요.`
  }
}

// 명령어 팩토리
const commandFactory = (commandName: string, posts: Post[], isUsingRealData: boolean): Command => {
  switch (commandName) {
    case 'help':
      return new HelpCommand(isUsingRealData)
    case 'ls':
      return new ListCommand(posts)
    case 'mv':
      return new MoveCommand(posts)
    default:
      return new UnknownCommand(commandName)
  }
}

import { create } from 'zustand'

interface TerminalState {
  history: string[]
  output: string[]
  addToHistory: (command: string) => void
  addToOutput: (line: string) => void
  clearOutput: () => void
}

export const useTerminalStore = create<TerminalState>((set) => ({
  history: [],
  output: [],
  addToHistory: (command) =>
    set((state) => ({
      history: [...state.history, command]
    })),
  addToOutput: (line) =>
    set((state) => ({
      output: [...state.output, line]
    })),
  clearOutput: () => set({ output: [] })
}))

import React from 'react'
import styled from '@emotion/styled'
import { TerminalCommands } from '../Terminal/TerminalCommands'

const Container = styled.div`
  padding: 1rem;
  padding: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
`

const TerminalContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export function GameSection() {
  return (
    <Container>
      <TerminalContainer>
        <TerminalCommands />
      </TerminalContainer>
    </Container>
  )
}

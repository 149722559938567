import { graphql, useStaticQuery } from 'gatsby'

export interface Post {
  id: string
  shortId: string
  title: string
  tags: string[]
  slug: string
}

// GraphQL로 실제 데이터를 가져오는 훅
export const useBlogPosts = (): Post[] => {
  try {
    // Gatsby의 useStaticQuery 훅을 사용하여 모든 블로그 포스트 가져오기
    const data = useStaticQuery(graphql`
      query GetBlogPosts {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                title
                tags
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `)

    if (!data || !data.allMarkdownRemark || !data.allMarkdownRemark.edges) {
      console.warn('블로그 포스트 데이터 구조가 예상과 다릅니다.')
      return []
    }

    // 데이터 확인용 로깅
    console.log('GraphQL 쿼리 결과:', data.allMarkdownRemark.edges.length, '개의 포스트 발견')
    if (data.allMarkdownRemark.edges.length > 0) {
      console.log('첫 번째 포스트 예시:', data.allMarkdownRemark.edges[0].node)
    }

    // GraphQL 결과에서 필요한 데이터만 추출하여 Post 배열로 변환
    return data.allMarkdownRemark.edges
      .map((edge: any, index: number) => {
        // 블로그 데이터 구조 확인
        if (!edge || !edge.node) {
          console.warn(`블로그 포스트 데이터 구조 문제 (인덱스: ${index})`)
          return null
        }

        return {
          id: edge.node.id || `unknown-id-${index}`,
          shortId: `post-${index + 1}`,
          title: edge.node.frontmatter?.title || `제목 없음 (${index + 1})`,
          tags: edge.node.frontmatter?.tags || [],
          slug: edge.node.fields?.slug || `unknown-slug-${index + 1}`
        }
      })
      .filter(Boolean) // null 항목 제거
  } catch (error) {
    console.error('블로그 포스트 데이터를 가져오는 중 오류 발생:', error)
    // 오류 발생시 빈 배열 반환
    return []
  }
}

// 개발 및 테스트를 위한 더미 데이터
export const getDummyPosts = (): Post[] => {
  return [
    {
      id: 'post-1-uuid',
      shortId: 'post-1',
      title: '첫 번째 포스트',
      tags: ['프로그래밍', '리액트'],
      slug: 'first-post'
    },
    {
      id: 'post-2-uuid',
      shortId: 'post-2',
      title: '두 번째 포스트',
      tags: ['프로그래밍', '타입스크립트'],
      slug: 'second-post'
    },
    {
      id: 'post-3-uuid',
      shortId: 'post-3',
      title: '세 번째 포스트',
      tags: ['일상'],
      slug: 'third-post'
    }
  ]
}

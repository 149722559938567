import React, { useEffect, useContext, createContext, useState } from 'react'
import { useBlogPosts, getDummyPosts, Post } from './postData'
import { Terminal } from './index'

// 커맨드 처리를 위한 컨텍스트 생성
interface TerminalCommandsContextType {
  posts: Post[]
  isUsingRealData: boolean
}

const TerminalCommandsContext = createContext<TerminalCommandsContextType>({
  posts: [],
  isUsingRealData: false
})

// 컨텍스트를 사용하기 위한 훅
export const useTerminalCommands = (): TerminalCommandsContextType => {
  return useContext(TerminalCommandsContext)
}

// Terminal 컴포넌트를 감싸는 래퍼 컴포넌트
export const TerminalCommands: React.FC = () => {
  // 컴포넌트 최상위 레벨에서 직접 useStaticQuery 호출 (useBlogPosts를 통해)
  // 실패해도 빈 배열을 반환하므로 안전함
  let realPosts: Post[] = []
  let initialIsUsingRealData = false

  try {
    realPosts = useBlogPosts()
    initialIsUsingRealData = realPosts.length > 0

    if (initialIsUsingRealData) {
      console.log('성공적으로 실제 블로그 데이터를 불러왔습니다.', realPosts.length)
    } else {
      console.log('블로그 데이터가 없어 더미 데이터를 사용합니다.')
    }
  } catch (error) {
    console.error('블로그 포스트 데이터를 가져오는 중 오류 발생:', error)
  }

  // 실제 데이터가 없는 경우 더미 데이터 사용
  const initialPosts = initialIsUsingRealData ? realPosts : getDummyPosts()

  const [posts, setPosts] = useState<Post[]>(initialPosts)
  const [isUsingRealData, setIsUsingRealData] = useState<boolean>(initialIsUsingRealData)

  // 개발 디버깅을 위한 데이터 표시
  useEffect(() => {
    console.log(`현재 데이터 소스: ${isUsingRealData ? '실제 데이터' : '더미 데이터'}`)
    console.log(`포스트 수: ${posts.length}`)
    if (posts.length > 0) {
      console.log('첫 번째 포스트:', posts[0])
    }
  }, [posts, isUsingRealData])

  return (
    <TerminalCommandsContext.Provider value={{ posts, isUsingRealData }}>
      <Terminal />
    </TerminalCommandsContext.Provider>
  )
}

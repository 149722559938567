import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import { GameSection } from './GameSection'
import styled from '@emotion/styled'

interface Props {
  children: ReactNode
}

export function RootLayout({ children }: Props) {
  return (
    <SplitLayout>
      <LeftPanel>
        <GameSection />
      </LeftPanel>
      <RightPanel>
        {Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child)
          }
          return child
        })}
      </RightPanel>
    </SplitLayout>
  )
}

const SplitLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  /* 768px 이하의 화면에서 숨김 처리 */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const RightPanel = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: auto;
`

const LeftPanel = styled.div`
  width: 100%;
  min-height: 100%;
  overflow: auto;

  /* 768px 이하의 화면에서 숨김 처리 */
  @media (max-width: 768px) {
    display: none;
  }
`
